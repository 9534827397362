import { useQuery } from '@tanstack/vue-query';
import useApiClient from './useApiClient';

const KEY_CREDIT_BALANCE_BY_CUSTOMER_ID = 'creditBalanceByCustomerId';
const KEY_CREDIT_BATCHES_BY_CUSTOMER_ID = 'creditBatchesByCustomerId';

/**
 * Composable for communicating with credit APIs.
 */
export default function useCreditsApi() {
  const apiClient = useApiClient();

  const getCreditBalance = () => {
    const queryFn = async () => {
      return await apiClient.credits.retrieveCustomerCreditBalance({
        customerId: '_me',
      });
    };
    const queryKey = [KEY_CREDIT_BALANCE_BY_CUSTOMER_ID];
    return useQuery({ queryKey, queryFn });
  };

  /**
   * List credit batches of the customer.
   *
   * @param onlyActive If true, return only active batches, which have
   *   remainingCredit > 0 and are not expired. If false, return all batches.
   */
  const listCreditBatches = (onlyActive = true) => {
    const queryFn = async () => {
      const response = await apiClient.credits.listCustomerCreditBatches({
        customerId: '_me',
        onlyActive: onlyActive ? 'true' : 'false',
      });
      return response.items;
    };
    const queryKey = [KEY_CREDIT_BATCHES_BY_CUSTOMER_ID];
    return useQuery({ queryKey, queryFn });
  };

  return {
    getCreditBalance,
    listCreditBatches,
  };
}
